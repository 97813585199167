import * as React from "react"
import { spacing, styled } from "../stitches.config"

import { Link } from "../components/Link"
import SEO from "../components/SEO"
import Typography from "../components/Typography"

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,

  alignItems: 'center',
  justifyContent: 'center',

  paddingTop: '$section',
})

export const Description = styled(Typography, {
  marginTop: spacing(2)
})

const NotFoundPage = () => {
  return (
    <Container>
      <SEO title="Not Found" />
      <Typography variant="h2" as={'h1'} style={{ fontWeight: 700 }}>Page not found</Typography>


      <Description variant="body1" >
        {'This page does not exist, '}
        <Link to="/">Go home</Link>.
      </Description>
    </Container>
  )
}

export default NotFoundPage
